.approve-div {
  min-height: 100vh;
}

.approve-main {
  padding: 20px;
  border-radius: 8px;
  width: 800px;
  margin: auto;
}

.main-head {
  font-family: Fira Sans;
  font-size: 44px;
  line-height: 60.51px;
  color: #333;
  text-align: center;
  width: 100%;
}

.form-main-title {
  font-family: math;
  font-weight: 400;
  font-size: 30px;
  line-height: 60.51px;
  margin-bottom: 10px;
  color: #333;
}

.approve-content {
  font-family: fangsong;
  font-size: 17px;
  text-align: -webkit-center;
}

.form-main {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.form-part {
  margin-bottom: 30px;
}

.approve-btn-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reset-btn {
  background-color: #ffc107;
  border-color: #ffc107;
}

.approve-btn {
  background-color: #343a40;
  border-color: #343a40;
}

.approve-btn:hover {
  background-color: #23272b;
  border-color: #23272b;
}

.text-area {
  resize: none;
}

.select-error {
  margin-left: 0px;
}

.preapprove-form-main {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 576px) {
  .main-head {
    font-size: 35px;
  }
  .approve-content {
    font-size: 16px;
  }
  .form-main-title {
    font-size: 25px;
  }
}
