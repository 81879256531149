.nav-logo {
    cursor: pointer;
}

.nav-main {
    height: 80px;
    padding: 10px;
}

.nav-item {
    font-family: "IBM Plex Sans", sans-serif;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-right: 1.2%;
    background-color: transparent;
    border: none;
}

.nav-item-btn {
    font-family: "IBM Plex Sans", sans-serif;
    width: 226px;
    height: 52px;
    background-color: #000000;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 24px;
}

.nav-item-btn:hover {
    color: white;
}

.nav-item:hover {
    color: #E5B80B;
}

.nav-item.active {
    color: #E5B80B;
}

.nav-item.active::after {
    content: "";
    display: block;
    width: 70%;
    height: 2px;
    background-color: #DF2424;
    margin-top: 3px;
}

.nav-item-btn:hover {
    color: #E5B80B;
}

.nav-item-btn.active {
    color: #E5B80B;
}

.nav-item-btn.active::after {
    content: "";
    display: block;
    width: 70%;
    height: 2px;
    background-color: #DF2424;
    margin-top: 3px;
    margin-left: 24px;
}

.offcanvas-backdrop.show {
    opacity: 0;
}

@media only screen and (max-width: 576px) {
    .nav-logo {
        width: 105%;
        height: 40px;
    }
}

@media only screen and (max-width: 1200px) {
    .nav-logo {
        width: 30% !important;
        /* height: 40px; */
    }
}

@media only screen and (max-width: 922px) {
    .nav-logo {
        width: 70% !important;
        /* height: 40px; */
    }
}

@media only screen and (max-width: 574px) {
    .nav-item-btn {
        width: 100% !important;
        /* height: 40px; */
    }
}

@media only screen and (max-width: 363px) {
    .navbar-brand {
        margin: 0px !important;
    }
}

@media only screen and (max-width: 992px) {
    .nav-item {
        margin: 0px;
        padding: 0px 5px;
        font-size: 16px;
    }

    .nav-item-btn {
        width: 181px;
        height: 40px;
    }
}

@media only screen and (max-width: 922px) {
    .nav-item {
        font-size: 14px;
    }

    .nav-logo {
        width: 45% !important;
    }

    .nav-item-btn {
        width: 130px;
        height: 30px;
        font-size: 11px;
    }
}

@media only screen and (max-width: 498px) {
    .nav-logo {
        width: 60% !important;
    }
}

@media only screen and (max-width: 722px) {
    .nav-item {
        font-size: 13px;
    }
}

.offcanvas-body {
    text-align: center;
}

.navbar-toggler {
    padding: 0px !important;
}

@media only screen and (max-width: 576px) {
    .nav-item.active::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #DF2424;
        margin-top: 3px;
    }
    .nav-item-btn.active::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #DF2424;
        margin-top: 3px;
        margin-left: 24px;
    }
    .nav-item-btn{
        width: 100%;
        margin-top: 10px;
    }
}

#reviews-section{
    margin-bottom: 30px;
}