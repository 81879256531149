.label {
  padding: 1% 0%;
}

.contact-img {
  height: 500px;
}

.contact-btn {
  margin-top: 2%;
  width: 162px;
  height: 52px;
}

.custom-button {
  background-color: black;
  color: white;
}

.custom-button:hover {
  color: goldenrod;
}

.error {
  color: red;
}

.main-title {
  font-family: math;
  font-weight: 1000;
  font-size: 44px;
  line-height: 60.51px;
}

.main-head {
  font-family: Fira Sans;
  font-size: 44px;
  line-height: 60.51px;
  color: #333;
  text-align: center;
  width: 100%;
}
.approve-content {
  font-family: fangsong;
  font-size: 17px;
  text-align: -webkit-center;
}

.contact-main {
  text-align: center !important;
}

.form-main {
  height: auto;
  border-radius: 8px;
  margin-top: 20px;
}

.contact-div {
  font-family: "IBM Plex Sans", sans-serif;
  height: 100vh;
}

.contact-div {
  position: relative;
}

.contact {
  max-width: 800px;
  margin: auto;
  padding: 30px 15px;
}

.form-main {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.form-box {
  padding: 20px;
}

.label {
  font-weight: bold;
}

.contact-btn {
  width: 100%;
  margin-top: 20px;
}

.textarea {
  resize: none;
}

.form-label {
  font-family: New Roman;
  font-size: 18px;
}

.form-para {
  font-size: 17px;
  font-family: fangsong;
}

@media only screen and (max-width: 576px) {
  .main-title {
    font-size: 35px !important;
  }
  .form-para {
    font-size: 16px !important;
  }
  .form-head {
    font-size: 20px !important;
  }
}
