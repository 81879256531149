.slider-main {
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
}

.slider-container {
  position: absolute;
  width: 100%;
  margin: auto;
  /* top: 10px; */
  top: -38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slider-head {
  font-family: Fira Sans;
  font-size: 44px;
  line-height: 60.51px;
  text-align: center;
  margin-bottom: 60px;
}

.slider-name {
  font-family: math;
  font-weight: 600;
  line-height: 28px;
  font-size: 20px;
}

.slider-role {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.slider-para {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  width: 591px;
  height: 84px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slick-slider {
  text-align: -webkit-center;
}

.slick-dots {
  bottom: -32px;
}

.slick-dots li.slick-active button:before {
  color: #e5b80b;
  height: -70px;
  border: 1px solid #e5b80b;
  border-radius: 11px;
  line-height: 16px;
  padding-right: 0.7px;
  padding-top: 0.7px;
}

.slick-dots li button:before {
  color: #ddc56c;
}

.line-main {
  bottom: -10px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  position: absolute;
  bottom: 42px;
}

.left-line {
  width: 136.56px;
  height: 5px;
  background-image: linear-gradient(to left, #e5b80b, transparent);
}

.right-line {
  background-image: linear-gradient(to right, #e5b80b, transparent);
  width: 136.56px;
  height: 5px;
}

.slider-img {
  height: 62.79px;
  width: 62.79px;
  position: absolute;
  top: -30px;
}

@media only screen and (max-width: 1301px) {
  .slider-back {
    height: 100%;
    width: 100%;
    margin-top: -54px;
    margin-top: -65px !important;
  }
}

.slider-content-img {
  height: auto;
  width: 240px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

.slick-current .slider-img,
.slick-center .slider-img {
  width: 133.42px !important;
  height: 133.42px !important;
  top: -30px !important;
  bottom: -30px !important;
}

.slick-current .slider-img-1 {
  width: 133.42px !important;
  height: 133.42px !important;
  position: absolute;
  top: -30px;
}

.slick-current {
  height: 100%;
  width: 100%;
  position: relative;
}

.slick-current .slider-content-img,
.slick-center .slider-content-img {
  bottom: -95px;
}

.slick-current .slider-content-img,
.slick-center .slider-content-img {
  height: 208px;
}

.around-before {
  height: 300px;
}

.slider-content-img {
  bottom: -30px;
}

.set-img {
  width: 98.1px;
  height: 97.32px;
  position: absolute;
  top: 15px;
}

.img-main {
  display: flex;
  justify-content: center;
}

.slick-list {
  height: 200px;
  top: -45px;
  margin-top: 65px;
}

.slider-back-img {
  margin-top: -66px;
  width: 100%;
  height: 76vh;
}

@media only screen and (max-width: 993px) {
  .slick-list {
    margin-top: -40px !important;
    top: 0px !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 576px) {
  .slider-head {
    font-size: 35px;
  }

  .slider-para {
    font-size: 12px;
    width: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-main {
    height: 100vh;
  }

  .slider-back-img {
    height: 91vh;
    margin-top: -140px;
  }

  .slider-container {
    top: -48px;
  }

  .slider-head {
    margin-bottom: 75px;
  }
}

@media only screen and (max-width: 993px) {
  .slider-container {
    top: -65px;
  }

  .second-slider-main {
    margin-top: 40px;
  }

  .slider-para {
    font-size: 14px;
    width: auto;
  }
}

@media only screen and (max-width: 1399px) {
  .slider-main {
    height: auto;
    margin-top: 22px;
  }

  .slick-list {
    margin-top: 15px;
  }
}

/*@media only screen and (max-width: 1399px) {*/
/*    .slider-back-img {*/
/*        height: 58vh !important;*/
/*        margin-top: -87px !important;*/
/*    }*/
/*}*/

.slick-next:before,
.slick-prev:before {
  display: none;
}

@media only screen and (max-width: 1399px) {
  .slider-back-img {
    height: 68vh !important;
    margin-top: -113px !important;
  }
}

@media only screen and (max-width: 768px) {
  .slider-back-img {
    height: 64vh !important;
    margin-top: -113px !important;
  }
}

@media only screen and (max-height: 680px) {
  .slider-back-img {
    height: 90vh !important;
    margin-top: -113px !important;
  }
}

@media only screen and (min-height: 679px) and (max-height: 840px) {
  .slider-back-img {
    height: 80vh !important;
    margin-top: -113px !important;
  }
}
