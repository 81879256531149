.CardImage {
  width: 100%;
  height: 100%;
  object-fit: none;
}

.card {
  width: 100%;
  height: 100%;
  border: none;
}

.card-heading {
  font-family: Fira Sans;
  font-size: 40px;
  line-height: 60.51px;
}

.card-content {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #828282;
}

.card-row {
  margin-top: 4%;
}
