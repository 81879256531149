.footer-main {
  background-color: #232323;
  height: auto;
  padding-top: 25px;
  align-items: center;
}

.footer {
  background-color: #232323;
  height: auto;
  position: relative;
}

.topic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: flex;
  height: 221px;
  width: 123px;
  flex-direction: column;
  justify-content: start;
}

.page {
  font-weight: 500;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 24px;
  color: #454545;
}

.name-social {
  width: 50vw;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.topic-pages {
  /* height: 221px;
    width: 150px; */
}

.footer-menu {
  color: #c4c4c4;
  font-weight: 700;
  line-height: 28px;
  font-size: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.footer-logo {
  width: 243.71px;
  height: 52px;
}

.footer-para {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #c4c4c4;
  width: 547px;
}

.social-main {
  width: 214px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.google-img,
.twitter-img,
.instagram-img,
.linkedin-img {
  width: 16px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social-main-div {
  width: 30px;
  height: 30px;
  background-color: #2387c0;
  position: relative;
  border-radius: 50%;
}

.footer-child-menu {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #c4c4c4;
  font-family: "IBM Plex Sans", sans-serif;
  cursor: pointer;
}

.footer-address {
  font-family: math;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #c4c4c4;
  /* width: 547px; */
}

.rights-para {
  font-size: 15px;
  font-family: math;
  color: #d7cfcf;
  bottom: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
  background-color: #383838;
}

.arrow-pointer {
  cursor: pointer;
}

.footer-topics {
  display: flex;
  margin-top: 3%;
  /* justify-content: center; */
}

@media only screen and (max-width: 576px) {
  .footer-child-menu {
    font-size: 12px;
    line-height: 10px;
  }
  .footer-menu {
    font-size: 15px;
  }
}
