.connect-btn {
  background-color: #000000;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  width: 152px;
  height: 52px;
  padding: 14px, 24px, 14px, 24px;
  text-align: center;
}

.connect-btn {
  color: white;
  width: 153px;
  height: 52px;
  margin-bottom: 5%;
}

.home-content {
  height: 360px;
  width: 590px;
}

.home-para {
  color: #828282;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.heading {
  font-family: Fira Sans;
  font-size: 44px;
  font-weight: 400;
  line-height: 60.51px;
  margin-top: 5%;
}

.home-main {
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  background-color: #fff5e5;
  padding-bottom: 50px;
  height: auto;
}

.home-content-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-img {
  width: 486px;
  height: 468px;
}

.connect-btn:hover {
  color: #e5b80b;
}

.connect-btn.active {
  color: #e5b80b;
}

.connect-btn.active::after {
  content: "";
  display: block;
  width: 70%;
  height: 2px;
  background-color: #df2424;
  margin-top: 3px;
}

body {
  overflow-x: hidden;
}

.home-main-content {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 576px) {
  .heading {
    font-size: 28px;
  }

  .home-para {
    font-size: 16px;
  }

  .connect-btn {
    font-size: 12px;
    width: 119px;
    height: 39px;
    margin-bottom: 5%;
  }

  .service-head {
    font-size: 28px;
  }

  .service-para {
    font-size: 16px;
  }

  .card-heading {
    font-size: 28px;
  }

  .card-content {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .home-main-content {
    display: flex;
    flex-direction: column;
  }

  .heading {
    width: 100% !important;
  }
}

@media only screen and (max-width: 922px) {
  .heading {
    font-size: 35px !important;
  }
}
