.service-head {
  margin-bottom: 25px;
  margin-top: 60px;
  font-family: Fira Sans;
  font-size: 44px;
  line-height: 60.51px;
  text-align: center;
}

.service-para {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #828282;
  text-align: center;
}
