.back-btn {
    width: 80px;
    color: black;
    text-decoration: none;
    font-size: 15px;
    line-height: 16.8px;
    font-weight: 600;
    font-family: "IBM Plex Sans", sans-serif;
}

.back-btn-main {
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-family: fangsong;
    align-items: center;
}

.back-btn {
    height: 14.38px;
    width: 8.13px;
}

.back-btn-img {
    margin-top: 15px;
}