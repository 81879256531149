.blog-img {
  width: 24px;
  height: 24px;
}

.blog-sub-head {
  color: #ffffff;
  font-family: math;
  font-weight: 100;
  font-size: 24px;
  line-height: 60.51px;
  width: 100%;
  height: 36px;
}

.blog-content {
  color: #ffffff;
  font-family: math;
  font-weight: 100;
  font-size: 20px;
  /* line-height: 60.51px; */
  width: 100%;
  /* height: 100%; */
}

.blog-col {
  width: 100%;
  height: 174px;
}

.blog-main-div {
  border: 1px solid #000000; /* Add border to rows */
  margin-bottom: 10px; /* Add space between rows */
  margin-top: 10px;
}

.blog-column {
  border: 1px solid #000000; /* Add border to columns */
  padding: 10px; /* Add padding inside columns */
}

.card {
  border: none !important;
}

.blog-main {
  background-color: #010101;
  height: 100%;
  margin: 3% 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.blog-head {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 60px;
  font-family: Fira Sans;
  font-size: 44px;
  line-height: 60.51px;
}

@media only screen and (max-width: 576px) {
  .blog-head {
    font-size: 28px;
  }
  .blog-sub-head {
    font-size: 18px;
  }
  .blog-content {
    font-size: 16px;
  }
}
